import { Link } from "gatsby"
import React, { useEffect } from "react"
import {
    FormattedMessage,
    FormattedHTMLMessage,
    injectIntl
} from "gatsby-plugin-intl"
import { useMutation } from "@apollo/client"
import Layout from "../../components/Layout"
import SearchEngineOptimization from "../../components/SearchEngineOptimization"
import withLocation from "../../hocs/withLocation"
import { CREATE_REFERRAL } from "../../schemas/referral"
import styled from "styled-components"

const StoreContainer = styled.div`
    margin-top: 5rem 0 3rem;
`

export const StyledLink = styled(Link)`
    @media (max-width: 416px) {
        display: inline-block;
        margin-bottom: 1rem;
        margin-left: 0rem !important;
    }
    @media (max-width: 428px) {
        margin-left: 0.25rem !important;
    }
`

const CircuitPage = ({ intl, params }) => {
    const [createReferral] = useMutation(CREATE_REFERRAL, {
        onCompleted: (data) => {},
        onError: (error) => {}
    })
    const { referrer } = params

    useEffect(() => {
        if (referrer) {
            createReferral({
                variables: {
                    input: {
                        code: "CIRCUIT",
                        referrer
                    }
                }
            })
        }
    }, [referrer, createReferral])

    return (
        <Layout pageInfo={{ pageName: "circuit" }}>
            <SearchEngineOptimization
                lang={intl.locale}
                title={intl.formatMessage({ id: "pages.circuit.seo.title" })}
                description={intl.formatMessage({
                    id: "pages.circuit.seo.description"
                })}
                image={`${process.env.GATSBY_API_URL}image/circuit/${params.referrer}`}
            />
            <h1>
                <FormattedMessage id="pages.circuit.title" />
            </h1>
            <p>
                <FormattedHTMLMessage id="pages.circuit.text" />
            </p>

            <StoreContainer>
                <StyledLink
                    to="https://play.google.com/store/apps/details?id=com.satimoto"
                    target="_blank"
                >
                    <img
                        src="/buttons/download-google-play.png"
                        alt="Google Play"
                    />
                </StyledLink>
                <StyledLink
                    to="https://apps.apple.com/app/satimoto/id6444064066"
                    target="_blank"
                    style={{ marginLeft: "1.25rem" }}
                >
                    <img
                        src="/buttons/download-app-store.png"
                        alt="App Store"
                    />
                </StyledLink>
            </StoreContainer>
        </Layout>
    )
}

export default withLocation(injectIntl(CircuitPage))
